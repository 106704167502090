<script setup>
import { useGlobalStore } from '~/store/globalStore.js'

const store = useGlobalStore()

const navigation = [{ name: 'Annotator', path: '/app/annotator' }]

// Check if we are on the client-side to avoid SSR issues
if (process.client) {
  // Add any client-side only logic here
}
</script>

<template>
  <div class="mx-auto px-2 sm:px-6 lg:px-8">
    <div class="relative flex h-14 items-center justify-between">
      <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
        <div class="flex flex-shrink-0 items-center">
          <NuxtLink to="/" class="flex h-6 w-auto">
            <img src="/img/heart.svg" alt="Your Company" />
            <span class="text-md font-bold text-primary ml-2">AnnotateImage</span>
          </NuxtLink>
        </div>
        <div class="hidden sm:ml-6 sm:block">
          <div class="flex space-x-4">
            <NuxtLink
              v-for="item in navigation"
              :to="item.path"
              :key="item.name"
              class="text-primary font-medium transition duration-300 ease-in-out"
              :class="[
                item.name === $route.name
                  ? 'bg-accent-faded text-accent hover:text-muted'
                  : 'text-primary hover:text-muted',
                'px-4 py-1 rounded-md text-sm font-medium',
              ]"
              :aria-current="item.current ? 'page' : undefined"
            >
              {{ $t('navigation.' + item.name.toLowerCase()) }}
            </NuxtLink>
          </div>
        </div>
      </div>
      <div
        class="absolute gap-5 inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"
      >
        <div
          class="hidden tablet:block h-6 w-px bg-accent-faded border-l border-gray-200 border-opacity-25"
        ></div>
        <!-- Display user's name if available -->
        <ProfilTool v-if="store.user.email" />
        <div v-else class="hidden tablet:flex gap-2">
          <NuxtLink
            :to="{ name: 'About' }"
            class="text-primary hover:bg-gray-800 hover:text-white px-4 py-1 rounded-md text-sm font-medium"
          >
            {{ $t('navigation.about') }}
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>
